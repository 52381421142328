<template>

  <div>
    <!-- Table Container Card -->

    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <b-card no-body>
    
      <b-card-body>
        <b-row>
          

          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
            
            <!-- <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              v-model="siteData"
              @input="filterTable"
            >
            <template #selected-option="{ site_name }">
              <span v-if="site_name.length < 23">{{site_name}}</span>
              <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
            </template>

          </v-select> -->

            <b-form-select v-model="siteData" @change="filterTable(); updateDateFormat();">

              <b-form-select-option value="" disabled>Select</b-form-select-option>
              
              <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
              
              
            </b-form-select>
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
            <b-form-datepicker v-model="start" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="updateStart()"/>
          </b-col>

          <b-col cols="12" md="3" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <b-form-datepicker @change="filterTable" v-model="end" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" @input="filterTable" :min="disabledDates()"/>
          </b-col>

          
          <b-col cols="12" md="3" class="mb-md-0 mobile_tab_max_width_flex  "></b-col>
            <b-form-select v-model="vuser" @change="filterTable()">
              <b-form-select-option value="" disabled>Validator</b-form-select-option>
              <b-form-select-option :value="vuser.key" v-for="(vuser,index) in vusers" :key="index">{{vuser.name | capitalize}}</b-form-select-option>
            </b-form-select>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >

    <!-- <b-tabs content-class="mt-2" justified>
      <b-tab title="Checklist" v-if="checkPermission($route.name, 'Checklist Download')"> -->

        <DailyChecklistReport :siteData="siteData" :start_date="start" :end_date="end" :date_format="date_format" :vusers="vusers" :vuser="vuser" />

      <!-- </b-tab>

      <b-tab title="Supervisor Daily Checklist" v-if="checkPermission($route.name, 'Daily Checklist Download')" >

        <DailyChecklistReport :siteData="siteData" :start_date="start" :end_date="end" />

      </b-tab>
    </b-tabs> -->

      
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BCardBody,BFormDatepicker, BTabs,BTab,BFormSelect,BFormSelectOption,BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import moment from "moment";

import DailyChecklistReport from "./IndexComponent";

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BTabs,
    BTab,
    DailyChecklistReport,
    BFormSelect,
    BFormSelectOption,
    BBreadcrumb
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  filters: {
      moment: function(date) {
          return moment(date).format('DD MMM YYYY')
      },
    },
  data() {
    return {
       /*tableColumns: [
            { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '13%'}},
            { key: 'form_name', label: 'Form Name', sortable: true , thStyle:  {width: '13%'}},
            { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '13%'}},
            { key: 'location_name', label: 'Location Name', sortable: true , thStyle:  {width: '13%'}},
            { key: 'checked_by', label: 'Checked By', sortable: true , thStyle:  {width: '13%'}},
            { key: 'checked_date', label: 'Checked Date', sortable: true , thStyle:  {width: '13%'}},
            { key: 'updated_at', label: 'Last Updated', sortable: true , thStyle:  {width: '13%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '9%'}},
          ],*/
        date_format:'DD MMM YYYY',
        time_format:'HH:mm',
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        sites:[],
        siteData:''/*this.$route.params.site_id*/,
        // calendar vars 
        start:moment().tz('Asia/Singapore').toDate(),
        end:moment().tz('Asia/Singapore').toDate(),
        vusers:[],
        vuser:'all',
    }
  },
  methods : {
    /*dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site:this.siteData ? this.siteData._id :null,
                     start:moment(this.start).format('YYYY-MM-DD'),
                     end:moment(this.end).format('YYYY-MM-DD'),
                   },
                   api: '/api/all-checklists'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },*/
    filterTable(){
      //this.$refs.refUserListTable.refresh();
    },
    /*variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },*/
    
    /*download(pdf){
      window.open(pdf, '_blank');
    },*/
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
           role:this.$store.getters.currentUser.role,
           //om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              // if (this.sites.length == 1 ) {
              //     this.siteData = this.sites[0]._id;
              //     this.updateDateFormat();
              //     this.filterTable();
              // }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites',
                pdf_date_format: 'DD MMM YYYY',
                pdf_time_format: 'HH:mm'
              }
              this.sites.unshift(obj);

              this.siteData = this.defaultSite(this.sites)._id;
              this.filterTable();
              this.updateDateFormat();
              
              return this.sites;
          }
      });
    },
    
    // calendar functions
    updateStart(){
      this.end = moment(this.start).tz('Asia/Singapore').toDate();
      this.filterTable();
    },
    disabledDates(){
      if (this.start) {
        return moment(this.start).format('YYYY-MM-DD');
      }else{
        return moment().format('YYYY-MM-DD');
      }
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Reports',
      },{
        to:{name:'custom-forms-dashboard'},
        text: 'Form Dashboard',
      },{
        to:{name:'custom-forms-reports'},
        text:'Custom Forms'
      },{
        to:null,
        text:'Daily Supervisor Checklist',
        active:true
      }];
      return this.filterReportBreadCrum(item);
    },
    updateDateFormat(){
      if (this.siteData != '') {
          this.sites.forEach(item => {
            if (item._id == this.siteData) {
                this.date_format = item.pdf_date_format;
                this.time_format = item.pdf_time_format; 
            }
          })

      }else{
          this.date_format = 'DD MMM YYYY';
          this.time_format = 'HH:mm';
      }
    },

    getValidateUser(){
      return this.$store.dispatch(POST_API, {
        data:{
          form: 'Daily Supervisor Checklist'
        },
        api: '/api/get-validate-user-by-form-name'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;
          } else {
              this.showDismissibleAlert = false;
              this.vusers  = this.$store.getters.getResults.data;
              return this.vusers; 
          }
      });
    }
    
  },
  mounted(){
    this.allSites();
    this.getValidateUser();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
